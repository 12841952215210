import "../sass/main.scss";
import scrollToSection from "./components/scrollToSection";
import mobileMenu from "./components/mobileMenu";
import axios from "axios";
import WOW from "wow.js/dist/wow";

(function () {
  const links = document.querySelectorAll(".nav-link");
  const buttonScroll = document.querySelector(".scroll-down");
  const btnContact = document.querySelector("#btnContact");

  mobileMenu();
  buttonScroll.addEventListener("click", scrollToSection);
  links.forEach((link) => link.addEventListener("click", scrollToSection));

  const form = document.getElementById("form");

  form.addEventListener("submit", function (e) {
    e.preventDefault();

    const data = {
      name: document.querySelector("[name=name]").value,
      email: document.querySelector("[name=email]").value,
      phone: document.querySelector("[name=phoneNumber]").value,
      message: document.querySelector("[name=message]").value,
      agreement: document.querySelector("[name=confirm]").value,
    };

    axios
      .post("https://brandwise.cloud/dev/ambit/crm/lead", data)
      .then(function (response) {
        if (response.data.status === "ok") {
          document.getElementById("form").classList.add("d-none");
          document.getElementById("thx").classList.add("d-flex");
        } else {
          alert("err");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  });

  const header = document.querySelector(".header-title");
  const headerSubtitle = document.querySelector(".header-subtitle");
  const btnHeader = document.querySelector("#btnContact");

  setTimeout(() => {
    header.classList.add("animation-text");
  }, 300);
  setTimeout(() => {
    headerSubtitle.classList.add("animation-text");
  }, 400);
  setTimeout(() => {
    btnHeader.classList.add("animation-text");
  }, 500);

  $(btnHeader).click(function () {
    $(".header-expand-text").slideToggle(600, () => {
      const headerText = document.querySelector(".header-expand-text");
      const displayHeader = headerText.style.display;
      if (displayHeader === "block") {
        btnHeader.innerHTML = "Fold";
      } else {
        btnHeader.innerHTML = "Expand";
      }
    });
  });

  $(document).ready(function () {
    window.addEventListener("scroll", function () {
      if ($(window).scrollTop() <= 40) {
        $(".wow:not('.no-repeat')").removeAttr("style");
        $(".wow:not('.no-repeat')").removeClass("animated");
        new WOW().init();
      }
    });

    const buttons = document.querySelectorAll(" .custom-card__button");
    buttons.forEach((btn) => {
      btn.addEventListener("click", function () {
        const card = document.querySelector(`[data-id="${this.id}"]`);
        const expand = card.querySelector(".custom-card__expand-text");

        $(expand).slideToggle(600, function () {
          $(card).toggleClass("custom-card--expanded");
        });
        if ($(card).hasClass("custom-card--expanded")) {
          this.innerHTML = "Fold";
        } else {
          this.innerHTML = "Expand";
        }
      });
    });
  });
})();
